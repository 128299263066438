import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import PropTypes from 'prop-types';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton, Popover } from '@mui/material';
// mocks_
// import account from '../../../../_mock/account';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import customToast from '../../../utils/customToast';
import { defaultError } from '../../../utils/constants';
import useLocalStorage from '../../../hooks/useLocalStorage';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'My Profile',
    icon: 'eva:person-fill',
    // page: '/user/profile',
  },
  {
    label: 'Company Profile',
    icon: 'eva:person-fill',
    // page: '/user/company-profile',
  },
];
const buttonColor = '#dedede';
const textColor = 'black';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const accountData = useLocalStorage('sessionInfo')?.json || {};
  const account = {
    displayName:
      accountData?.firstName && accountData?.lastName
        ? `${accountData?.firstName} ${accountData?.lastName}`
        : `${accountData?.profile?.fullName}`,
    email: accountData?.email,
    role: accountData?.role,
    photoURL: '/assets/images/avatars/avatar_default.jpg',
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = async (value = null) => {
    try {
      if (value === 'logout') {
        const res = await apiMiddleWare({ url: '/users/logout', type: 'post' });
        if (!res?.data?.ok) {
          customToast({
            msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
            type: 'error',
          });
          return;
        }
        secureLocalStorage.setItem('sessionInfo', {
          roles: [''],
          json: {},
          xAuthAnalytic: '',
        });
        navigate('/admin/login');
      } else if (value) {
        navigate(value);
      }
      setOpen(null);
    } catch (err) {
      customToast({ msg: err.message || err?.msg || defaultError, type: 'error' });
    }
  };

  const handleOutsideClick = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[600], 0.8),
            },
          }),
        }}
      >
        {InitialIcon({
          initials: account.displayName
            .split(' ')
            .map((i) => i[0].toUpperCase())
            .join(''),
        })}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleOutsideClick}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClose(option.page)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={() => handleClose('logout')} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}

InitialIcon.propTypes = {
  initials: PropTypes.string,
};

function InitialIcon({ initials }) {
  return (
    <div
      style={{
        backgroundColor: buttonColor,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        borderRadius: 30,
        width: 40,
        height: 40,
      }}
    >
      <span style={{ color: textColor, fontSize: 20 }}>{initials}</span>
    </div>
  );
}

ShowcaseIcon.propTypes = {
  initials: PropTypes.string,
};
function ShowcaseIcon({ initials }) {
  return (
    <div
      style={{
        backgroundColor: buttonColor,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        borderRadius: 5,
        padding: '10px',
        width: 'auto',
        height: 40,
        marginRight: '10px',
      }}
    >
      <span style={{ color: textColor, fontSize: 20 }}>{initials}</span>
    </div>
  );
}
