import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, TextField, MenuItem, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// components
import Iconify from '../../../components/iconify';
// sections
import { AppCurrentVisits, AppWebsiteVisits, AppWidgetSummary } from '../../../sections/@dashboard/app';
import { defaultError, userRouteName } from '../../../utils/constants';
import customToast from '../../../utils/customToast';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import CommonModal from '../../../components/modal/CommonModal';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const navigate = useNavigate();

  const [modalData, setModalData] = useState({
    open: false,
  });
  const [callApi, setCallApi] = useState(false);
  const [filterData, setFilterData] = useState({
    searchTime: 'last7',
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    // console.log('api will be called', filterData);
    getOverviewData();
  }, [callApi]);

  useEffect(() => {
    getShowcaseList();
  }, []);

  useEffect(() => {
    if (filterData.searchTime === 'custom' && filterData.startDate !== '' && filterData.endDate !== '') {
      setCallApi((prev) => !prev);
    }
  }, [filterData]);

  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (value !== 'custom') {
      setCallApi((prev) => !prev);
    }
  };

  const getOverviewData = () => {};

  const getShowcaseList = async () => {
    try {
      const data = await apiMiddleWare({ type: 'get', url: '/showcase/list-all' });
      if (!data) {
        return false;
      }
      if (data?.data?.ok) {
        const allPacket = data?.data?.packet;
        if (!allPacket[0]) {
          setModalData((prev) => ({ ...prev, open: true }));
        }
        return true;
      }

      return false;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      return false;
    }
  };

  const modalClose = () => {
    setModalData((prev) => ({ ...prev, open: false }));
  };

  const goToShowcase = () => {
    navigate(`/${userRouteName}/showcase`);
  };

  return (
    <>
      <CommonModal
        title={`Alert`}
        open={modalData?.open}
        body={
          <div className="d-flex align-items-center justify-content-center">
            <Typography variant="h4" sx={{ p: 2 }}>
              You don't have any Showcase
            </Typography>
          </div>
        }
        customFooter={
          <div className="d-flex justify-content-center align-items-center w-100">
            <Button type="button" onClick={goToShowcase} variant="contained" className="mx-3">
              Create Showcase
            </Button>
            <Button type="button" onClick={modalClose} variant="outlined" className="mx-3">
              Skip
            </Button>
          </div>
        }
      />
      <Helmet>
        <title> Showcase Samaaro </title>
      </Helmet>

      <Container maxWidth="xl">
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} sx={{ mb: '2rem' }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                name="searchTime"
                id="outlined-select-currency"
                select
                label="Search Time"
                value={filterData?.searchTime}
                onChange={onChangeFilter}
                required
              >
                <MenuItem key={'last7'} value={'last7'}>
                  Last 7 Days
                </MenuItem>
                <MenuItem key={'last30'} value={'last30'}>
                  Last 30 Days
                </MenuItem>
                <MenuItem key={'custom'} value={'custom'}>
                  Custom
                </MenuItem>
              </TextField>
            </Grid>
            {filterData?.searchTime === 'custom' && (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <DatePicker
                    disableFuture
                    label="Start Date"
                    openTo="day"
                    views={['year', 'month', 'day']}
                    value={filterData?.startDate}
                    onChange={(newValue) => {
                      setFilterData((prev) => ({
                        ...prev,
                        startDate: newValue,
                      }));
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DatePicker
                    disableFuture
                    label="End Date"
                    openTo="day"
                    minDate={filterData?.startDate || dayjs()}
                    views={['year', 'month', 'day']}
                    value={filterData?.endDate}
                    onChange={(newValue) => {
                      setFilterData((prev) => ({
                        ...prev,
                        endDate: newValue,
                      }));
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
              </>
            )}
            {/* <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  name="boothId"
                  id="outlined-select-boothid"
                  select
                  label="Filter Booth"
                  // value={userData.countryCode.value}
                  // onChange={handleChange1}
                  required
                >
                  <MenuItem key={''} value={''}>
                    -- Select --
                  </MenuItem>
                </TextField>
              </Grid> */}
          </Grid>
        </LocalizationProvider>
        {/* </Box> */}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="No. of Leads" total={714000} icon={'ant-design:android-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="No. of Meetings Scheduled"
              total={1352831}
              color="info"
              icon={'ant-design:apple-filled'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Queries Received"
              total={1723315}
              color="warning"
              icon={'ant-design:windows-filled'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Most  Inquired Product" total={234} color="info" icon={'ant-design:bug-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Most Viewed Product" total={234} color="info" icon={'ant-design:bug-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Total Product Queries" total={234} color="info" icon={'ant-design:bug-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Most Popular Video" total={234} color="info" icon={'ant-design:bug-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Most Popular Document" total={234} color="info" icon={'ant-design:bug-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Average Session Duration"
              total={234}
              color="info"
              icon={'ant-design:bug-filled'}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
