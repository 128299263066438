import { toast } from 'react-toastify';

const autoCloseTime = 3000;
const theme = 'dark';
function customToast({ type, msg, customProp, updateId, updateType }) {
  if (type === 'success') {
    toast.success(msg, { autoClose: autoCloseTime, closeOnClick: true, theme, ...customProp });
  } else if (type === 'error') {
    toast.dismiss();
    toast.error(msg, { autoClose: autoCloseTime, theme, ...customProp });
  } else if (type === 'info') {
    toast.info(msg, { autoClose: autoCloseTime, theme, ...customProp });
  } else if (type === 'update') {
    toast.update(updateId, {
      render: msg,
      type: updateType,
      isLoading: false,
      autoClose: autoCloseTime,
      theme,
      ...customProp,
    });
  }
}

export default customToast;
