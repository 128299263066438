import { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import customToast from '../../../utils/customToast';
import Iconify from '../../../components/iconify';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import { defaultError, userRouteName, adminRouteName, showcaseRouteName } from '../../../utils/constants';

// ----------------------------------------------------------------------

const navigateData = {
  [userRouteName]: { loginLink: `/${userRouteName}/login`, registerLink: `/${userRouteName}/register` },
  [adminRouteName]: { loginLink: `/${adminRouteName}/login`, registerLink: `/${adminRouteName}/register` },
};

LoginForm.propTypes = {
  type: PropTypes.string,
};
export default function LoginForm({ type }) {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location?.state?.from?.pathname || location?.state?.pathname || null;

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: { value: '' },
    password: { value: '' },
  });
  const [adminData, setAdminData] = useState({
    email: '',
    password: '',
  });

  // user (owner dashbord)
  const navigateDashboard = () => {
    navigate(redirectTo || `/${type}/dashboard`, { replace: true });
  };

  // user/login
  const handleSubmit1 = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const id = toast.loading('Please wait...');
    try {
      const { email, password, role: type } = adminData;
      const sendData = { email, password };
      const res = await apiMiddleWare({ url: '/admin/login', data: sendData, type: 'post' });
      if (res?.data?.ok === true) {
        customToast({ updateId: id, type: 'update', msg: 'Login Successful', updateType: 'sucess' });
        // Cookies.set('x-auth', res?.data?.token);
        secureLocalStorage.setItem('sessionInfo', {
          roles: [`${res?.data?.role}`],
          json: res?.data?.userData,
          xAuthAnalytic: res?.data?.token,
        });
        // navigate('/under-construction');
        navigateDashboard();
      } else {
        customToast({
          updateId: id,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg,
          updateType: 'error',
        });
      }
      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      resetForm1();
      setIsLoading(false);
      return false;
    }
  };

  // user/login
  const resetForm1 = () => {
    setUserData((prev) => ({
      ...prev,
      email: { value: '' },
      password: { value: '' },
    }));
    setShowPassword(false);
  };

  // user/login
  const handleChange1 = (e) => {
    const { value, name } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        value,
      },
    }));
  };

  // admin/login
  const handleSubmitAdmin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const id = toast.loading('Please wait...');
    try {
      const { email, password, role: type } = userData;
      const sendData = { email: email?.value, password: password?.value };
      // const res = await apiMiddleWare({ url: '/admin/login', data: sendData, type: 'post' });
      // if (res?.data?.ok === true) {
      //   customToast({ updateId: id, type: 'update', msg: 'Login Successful', updateType: 'sucess' });
      //   // Cookies.set('x-auth', res?.data?.token);
      //   secureLocalStorage.setItem('sessionInfo', {
      //     roles: [`${res?.data?.role}`],
      //     json: res?.data?.userData,
      //     xAuthAnalytic: res?.data?.token,
      //   });
      //   // navigate('/under-construction');
      //   navigateDashboard();
      // } else {
      //   customToast({
      //     updateId: id,
      //     type: 'update',
      //     msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg,
      //     updateType: 'error',
      //   });
      // }
      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      resetForm1();
      setIsLoading(false);
      return false;
    }
  };

  // admin/login
  const resetFormAdmin = () => {
    setAdminData((prev) => ({
      ...prev,
      email: '',
      password: '',
    }));
    setShowPassword(false);
  };

  // admin/login
  const handleChangeAdmin = (e) => {
    const { value, name } = e.target;
    setAdminData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Typography variant="body2" sx={{ mb: 5 }}>
        Don’t have an account? {''}
        <Link to={navigateData[type].registerLink} state={location?.state?.from || location?.state}>
          Register
        </Link>
      </Typography>
      {type === userRouteName && (
        <form action="" onSubmit={handleSubmit1}>
          <Grid container spacing={2}>
            <Grid item sm={12} flexGrow={1}>
              <TextField
                fullWidth
                name="email"
                label="Email address"
                value={userData.email.value}
                onChange={handleChange1}
                required
              />
            </Grid>
            <Grid item sm={12} flexGrow={1}>
              <TextField
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={userData.password.value}
                onChange={handleChange1}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {/* </Stack> */}
          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
            {/* <Checkbox name="remember" label="Remember me" /> */}
            <Button type="button" onClick={resetForm1}>
              Reset Form
            </Button>
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" disabled={isLoading} variant="contained">
            Login
          </LoadingButton>
        </form>
      )}

      {type === adminRouteName && (
        <>
          <form action="" onSubmit={handleSubmitAdmin}>
            <Grid container spacing={2}>
              <Grid item sm={12} flexGrow={1}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email address"
                  value={adminData.email}
                  onChange={handleChangeAdmin}
                  required
                />
              </Grid>
              <Grid item sm={12} flexGrow={1}>
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={adminData.password}
                  onChange={handleChangeAdmin}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/* </Stack> */}
            <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
              {/* <Checkbox name="remember" label="Remember me" /> */}
              <Button type="button" onClick={resetFormAdmin}>
                Reset Form
              </Button>
            </Stack>
            <LoadingButton fullWidth size="large" type="submit" disabled={isLoading} variant="contained">
              Login
            </LoadingButton>
          </form>
        </>
      )}
    </>
  );
}
