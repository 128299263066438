/* eslint-disable prefer-template */
import axios from 'axios';
import Cookies from 'js-cookie';
import secureLocalStorage from 'react-secure-storage';
import useClearUser from '../hooks/useClearUser';
import customToast from './customToast';
import {
  API_VER,
  API_BASE,
  BASE_URL,
  defaultError,
  userRouteName,
  visitorRouteName,
  adminRouteName,
} from './constants';

const cleanerFunction = async () => {
  const sesInfo = secureLocalStorage.getItem('sessionInfo');
  secureLocalStorage.clear();
  Cookies.remove('x-auth');
  if (sesInfo.roles[0] === userRouteName) {
    window.location.href = BASE_URL + `/${userRouteName}/login`;
    // navigate('/user/login', { replace: true });
  } else if (sesInfo.roles[0] === visitorRouteName) {
    window.location.href = BASE_URL + `/login`;
    // navigate('/login', { replace: true });
  } else if (sesInfo.roles[0] === adminRouteName) {
    window.location.href = BASE_URL + `/${adminRouteName}/login`;
    // navigate('/admin/login', { replace: true });
  } else {
    window.location.href = BASE_URL + `/login`;
  }
};

const apiMiddleWare = async ({ type, url, data }) => {
  try {
    // const cleanerFunction = async () => {
    //   const sesInfo = secureLocalStorage.getItem('sessionInfo');
    //   secureLocalStorage.clear();
    //   Cookies.remove('x-auth');
    //   if (sesInfo.roles[0] === userRouteName) {
    //     window.location.href = BASE_URL + `/${userRouteName}/login`;
    //     // navigate('/user/login', { replace: true });
    //   } else if (sesInfo.roles[0] === visitorRouteName) {
    //     window.location.href = BASE_URL + `/login`;
    //     // navigate('/login', { replace: true });
    //   } else if (sesInfo.roles[0] === adminRouteName) {
    //     window.location.href = BASE_URL + `/${adminRouteName}/login`;
    //     // navigate('/admin/login', { replace: true });
    //   }
    // };
    // const clearFunction = useClearUser();
    let res;
    const sesInfo = secureLocalStorage.getItem('sessionInfo');
    if (type === 'get') {
      const headers = {
        'content-type': 'application/json',
      };
      headers['x-auth'] = Cookies.get('x-auth') || sesInfo?.xAuthAnalytic;
      res = await axios({
        method: 'GET',
        url: API_BASE + API_VER + url,
        headers,
      });
      if (res.status === 401) {
        // location.href = 'http:localhost:3000/'
        // clearFunction();
        console.log('clearner running GET');
        cleanerFunction();
      }
      // return res;
    } else if (type === 'post') {
      const headers = {
        'content-type': 'application/json',
      };
      headers['x-auth'] = Cookies.get('x-auth') || sesInfo?.xAuthAnalytic;
      res = await axios.post(API_BASE + API_VER + url, data, headers);
      if (res.status === 401) {
        // clearFunction();
        console.log('clearner running POST');
        cleanerFunction();
      }
    } else if (type === 'delete') {
      const headers = {
        'content-type': 'application/json',
      };
      headers['x-auth'] = Cookies.get('x-auth') || sesInfo?.xAuthAnalytic;
      res = await axios.delete(API_BASE + API_VER + url, headers);
      if (res.status === 401) {
        // clearFunction();
        console.log('clearner running DELETE');
        cleanerFunction();
      }
    }
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      cleanerFunction();
    }
    customToast({
      msg: err?.response?.data?.errors[0]?.message || err?.response?.data?.errors[0]?.msg || err?.message || err?.msg,
      type: 'error',
    });
    return false;
  }
};
export default apiMiddleWare;
