export const BASE_URL = 'http://analytics.sellers.reiconnect.online';
// export const BASE_URL = 'http://localhost:3000';

export const API_VER = 'v1';
export const API_BASE = '/analytics/';

export const defaultError = 'Something Went Wrong !!';
export const S3BucketBaseUrl = 'https://showcase-samaaro.s3.ap-south-1.amazonaws.com/';

export const userRouteName = 'user';
export const visitorRouteName = 'u';
export const showcaseRouteName = 'showcase';
export const adminRouteName = 'admin';

export const ckEditorConfig = {
  alignment: {
    options: ['left', 'right', 'center', 'justify'],
  },
  // wordCount: {
  //   onUpdate: (stats) => {
  //     // Prints the current content statistics.
  //     console.log(`Characters: ${stats.characters}\nWords: ${stats.words}`);
  //   },
  // },
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'alignment',
      'outdent',
      'indent',
      '|',
      'blockQuote',
      'insertTable',
      'undo',
      'redo',
    ],
  },
};
