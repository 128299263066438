import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  // maxHeight: '80vh',
  minWidth: '60vw',
  maxWidth: '70vw',
};
const bigStyle = {};
const titleStyle = {
  p: 3,
  borderBottom: '1px solid #d0d0d0',
};
const bodyStyle = {
  ...titleStyle,
  mt: 2,
  maxHeight: '53vh',
  overflowY: 'auto',
  maxWidth: '100%',
};
const footerStyle = {
  ...titleStyle,
  display: 'flex',
  justifyContent: 'end',
};

CommonModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  customStyle: PropTypes.string,
  customFooter: PropTypes.element,
  body: PropTypes.element,
};

export default function CommonModal({ open, onClose, onSubmit, title, body, customStyle, customFooter }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box
        sx={{
          ...style,
          '@media (max-width: 768px)': {
            width: '80vw',
            maxWidth: '90vw',
          },
        }}
        className={customStyle}
      >
        <Typography sx={titleStyle} id="modal-modal-title" variant="h4" component="h2">
          {title}
        </Typography>
        <Typography
          sx={{
            ...bodyStyle,
            '@media (max-width: 768px)': {
              maxWidth: '90vw',
            },
          }}
          id="modal-modal-description"
          component="div"
        >
          {body}
        </Typography>
        <Typography sx={footerStyle} id="modal-modal-description">
          {customFooter || (
            <>
              {onSubmit && (
                <Button sx={{ mr: 2 }} type="button" variant="contained" color="primary" onClick={onSubmit}>
                  Submit
                </Button>
              )}
              <Button type="button" variant="outlined" color="error" onClick={onClose}>
                Cancel
              </Button>
            </>
          )}
        </Typography>
      </Box>
    </Modal>
  );
}
