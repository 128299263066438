import Cookies from 'js-cookie';
import secureLocalStorage from 'react-secure-storage';

function useAuth() {
  const data = { auth: {}, sessionData: {} };
  const sesInfo = secureLocalStorage.getItem('sessionInfo');
  data.auth.user = sesInfo?.xAuthAnalytic || '';
  // data.auth.user = Cookies.get('x-auth') || '';
  data.auth.roles = sesInfo?.roles || [];
  data.sessionData = sesInfo?.json || {};
  return data;
}

export default useAuth;
