// component
import SvgColor from '../../../components/svg-color';
import { userRouteName, visitorRouteName, adminRouteName } from '../../../utils/constants';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'user-dashboard',
    path: `/${userRouteName}/dashboard/app`,
    icon: icon('ic_analytics'),
  },
  {
    title: 'user-user',
    path: `/${userRouteName}/dashboard/user`,
    icon: icon('ic_user'),
  },
  {
    title: 'visitor-product',
    path: `/${visitorRouteName}/dashboard/products`,
    icon: icon('ic_cart'),
  },
  {
    title: 'visitor-blog',
    path: `/${visitorRouteName}/dashboard/blog`,
    icon: icon('ic_blog'),
  },
  {
    title: 'admin-test',
    path: `/${adminRouteName}/dashboard/test`,
    icon: icon('ic_blog'),
  },
];

export default navConfig;
