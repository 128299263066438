import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { adminRouteName, userRouteName } from '../../utils/constants';

ProtectedLayout.propTypes = {
  allowedRoles: PropTypes?.array,
  children: PropTypes?.element,
};

function ProtectedLayout({ allowedRoles, children }) {
  const { auth } = useAuth();
  const location = useLocation();
  return auth.user ? (
    auth?.roles.find((i) => allowedRoles.includes(i)) ? (
      <>{children}</>
    ) : (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    )
  ) : (
    <Navigate to={`/${adminRouteName}/login`} state={{ from: location }} replace />
  );
}

export default ProtectedLayout;
