import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';

// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox, Typography, MenuItem, Grid } from '@mui/material';
// components
import customToast from '../../../utils/customToast';
import Iconify from '../../../components/iconify';
import apiMiddleWare from '../../../utils/useApiMiddleWare';
import { defaultError, userRouteName, adminRouteName } from '../../../utils/constants';

// ----------------------------------------------------------------------

const navigateData = {
  [userRouteName]: { loginLink: `/${userRouteName}/login`, registerLink: `/${userRouteName}/register` },
  [adminRouteName]: { loginLink: `/${adminRouteName}/login`, registerLink: `/${adminRouteName}/register` },
};

export default function RegisterForm({ type }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstName: { value: '' },
    lastName: { value: '' },
    email: { value: '' },
    countryCode: { value: '', list: [] },
    phone: { value: '' },
    otp: { value: '' },
    company: { value: '' },
    designation: { value: '' },
    industry: { value: '' },
    profilePass: { value: '' },
    token: '',
  });
  const [form1Status, setForm1Status] = useState(1); // {1:'Send OTP', 2:"Verify OTP",3:"Submit Form"}

  const [userData2, setUserData2] = useState({
    name: '',
    phone: '',
    email: '',
    company: '',
    designation: '',
    countryCode: { value: '', list: [] },
  });

  // useEffect
  useEffect(() => {
    if (type === userRouteName) {
      getCountryCode();
    }
  }, []);

  // user/register
  const handleSubmit1 = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const id = toast.loading('Please wait...');
    // toast.update(id, { render: 'All is good', type: 'success', isLoading: false, autoClose: 2000, theme: 'light' });
    try {
      if (form1Status === 1) {
        const { firstName, lastName, email, phone, countryCode } = userData;
        const sendData = {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          phone: phone.value,
          countryCode: countryCode.value,
        };
        const res = await apiMiddleWare({ url: '/users/send-otp', data: sendData, type: 'post' });
        if (res?.data?.ok === true) {
          customToast({ updateId: id, type: 'update', msg: 'OTP has been sent to email', updateType: 'sucess' });
          setForm1Status(2);
        } else {
          customToast({
            updateId: id,
            type: 'update',
            msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
            updateType: 'error',
          });
        }
      } else if (form1Status === 2) {
        const { firstName, lastName, email, phone, countryCode, otp } = userData;
        const sendData = {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          phone: phone.value,
          countryCode: countryCode.value,
          otp: otp.value,
        };
        const res = await apiMiddleWare({ url: '/users/verify-otp', data: sendData, type: 'post' });
        // res.token
        if (res?.data?.ok === true) {
          customToast({
            updateId: id,
            type: 'update',
            msg: 'OTP verified, Complete your Registration',
            updateType: 'sucess',
          });
          setUserData((prev) => ({
            ...prev,
            token: res.data.token,
          }));
          setForm1Status(3);
        } else {
          customToast({
            updateId: id,
            type: 'update',
            msg: res.data.errors[0].message || res?.data?.errors[0]?.msg || defaultError,
            updateType: 'error',
          });
        }
      } else if (form1Status === 3) {
        const { company, designation, industry, profilePass, token } = userData;
        const sendData = {
          company: company.value,
          designation: designation.value,
          industry: industry.value,
          password: profilePass.value,
          token,
        };
        const res = await apiMiddleWare({ url: '/users/register', data: sendData, type: 'post' });
        if (res?.data?.ok === true) {
          customToast({ updateId: id, type: 'update', msg: 'Registration Successful', updateType: 'sucess' });
          resetForm1();
          navidgateLogin();
        } else {
          customToast({
            updateId: id,
            type: 'update',
            msg: res.data.errors[0].message || res?.data?.errors[0]?.msg || defaultError,
            updateType: 'error',
          });
        }
      }
      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      resetForm1();
      setIsLoading(false);
      return false;
    }

    // navigate('/user/dashboard', { replace: true });
  };

  // user/register
  const resetForm1 = () => {
    setUserData((prev) => ({
      ...prev,
      firstName: { value: '' },
      lastName: { value: '' },
      email: { value: '' },
      phone: { value: '' },
      otp: { value: '' },
      company: { value: '' },
      designation: { value: '' },
      industry: { value: '' },
      profilePass: { value: '' },
      countryCode: {
        ...prev,
        value: '',
      },
      token: '',
    }));
    setForm1Status(1);
    setShowPassword(false);
  };

  // user/register
  const handleChange1 = (e) => {
    const { value, name } = e.target;
    // eslint-disable-next-line prefer-const
    let changedValue = value;
    if (name === 'phone') {
      changedValue = changedValue.replace(/[^0-9-]/g, '').replace(/(\..*?)\..*/g, '$1');
      if (changedValue.length >= 14) changedValue = changedValue.substring(0, 14);
    }
    if (name === 'otp') {
      changedValue = changedValue.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');
      if (changedValue.length >= 4) changedValue = changedValue.substring(0, 4);
    }
    setUserData((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: changedValue,
      },
    }));
  };

  async function getCountryCode() {
    setIsLoading(true);
    try {
      const resData = await apiMiddleWare({ url: '/json/country-code', type: 'get' });
      if (type === userRouteName) {
        setUserData((prev) => ({
          ...prev,
          countryCode: {
            ...prev.countryCode,
            list: resData.data.countryCode,
          },
        }));
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }
  const navidgateLogin = () => {
    navigate(`/${type}/login`, { state: location?.state });
  };

  const navidgateLogin2 = () => {
    navigate(`/login`, { state: location?.state });
  };

  // visitor handle chnage
  const handleChange2 = (e) => {
    const { value, name } = e.target;
    // eslint-disable-next-line prefer-const
    let changedValue = value;
    if (name === 'phone') {
      changedValue = changedValue.replace(/[^0-9-]/g, '').replace(/(\..*?)\..*/g, '$1');
      if (changedValue.length >= 14) changedValue = changedValue.substring(0, 14);
    }
    if (name === 'countryCode') {
      setUserData2((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          value: changedValue,
        },
      }));
    } else {
      setUserData2((prev) => ({
        ...prev,
        [name]: changedValue,
      }));
    }
  };

  // visitor reset
  const resetForm2 = () => {
    setUserData2((prev) => ({
      ...prev,
      name: '',
      phone: '',
      email: '',
      company: '',
      designation: '',
      countryCode: { ...prev.countryCode, value: '' },
    }));
    setShowPassword(false);
  };

  // visitor register form
  const handleSubmit2 = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const toastID = toast.loading('Please wait...');
    try {
      const { name, phone, email, company, designation, countryCode } = userData2;
      const sendData = {
        fullName: name,
        phone,
        email,
        company,
        designation,
        countryCode: countryCode?.value,
      };
      const res = await apiMiddleWare({ url: '/visitors/register', data: sendData, type: 'post' });
      if (res?.data?.ok === true) {
        navidgateLogin2();

        customToast({ updateId: toastID, type: 'update', msg: 'Registration Successful', updateType: 'sucess' });
      } else {
        customToast({
          updateId: toastID,
          type: 'update',
          msg: res?.data?.errors[0]?.message || res?.data?.errors[0]?.msg || defaultError,
          updateType: 'error',
        });
      }
      setIsLoading(false);
      return true;
    } catch (err) {
      customToast({ msg: err?.message || err?.msg || defaultError, type: 'error' });
      resetForm1();
      setIsLoading(false);
      return false;
    }

    // navigate('/user/dashboard', { replace: true });
  };

  return (
    <>
      <Typography variant="body2" sx={{ mb: 5 }}>
        Already have an account? {''}
        <Link to={navigateData[type].loginLink} state={location?.state}>
          Get started
        </Link>
      </Typography>
      {type === userRouteName && (
        <form action="" onSubmit={handleSubmit1}>
          {/* <Stack spacing={3}> */}
          <Grid container spacing={2}>
            {(form1Status === 1 || form1Status === 2) && (
              <>
                <Grid item sm={6} flexGrow={1}>
                  <TextField
                    fullWidth
                    name="firstName"
                    label="First Name"
                    value={userData.firstName.value}
                    onChange={handleChange1}
                    disabled={form1Status !== 1}
                    required
                  />
                </Grid>
                <Grid item sm={6} flexGrow={1}>
                  <TextField
                    fullWidth
                    name="lastName"
                    label="Last Name"
                    value={userData.lastName.value}
                    onChange={handleChange1}
                    disabled={form1Status !== 1}
                    required
                  />
                </Grid>

                <Grid item sm={12} flexGrow={1}>
                  <TextField
                    fullWidth
                    name="email"
                    label="Email address"
                    value={userData.email.value}
                    onChange={handleChange1}
                    disabled={form1Status !== 1}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4} flexGrow={1}>
                  <TextField
                    fullWidth
                    name="countryCode"
                    id="outlined-select-currency"
                    select
                    label="Country Code"
                    value={userData.countryCode.value}
                    onChange={handleChange1}
                    disabled={form1Status !== 1}
                    required
                  >
                    <MenuItem key={''} value={''}>
                      -- Select --
                    </MenuItem>
                    {userData?.countryCode?.list?.length > 0 &&
                      userData?.countryCode?.list?.map((i) => (
                        <MenuItem key={`${i?.code}-${i?.name}`} value={i?.code}>
                          {`${i?.name} (${i?.code})`}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item sm={8} flexGrow={1}>
                  <TextField
                    fullWidth
                    name="phone"
                    label="Mobile Number"
                    value={userData.phone.value}
                    disabled={form1Status !== 1}
                    onChange={handleChange1}
                    required
                  />
                </Grid>
              </>
            )}
            {form1Status === 2 && (
              <Grid item sm={8} flexGrow={1}>
                <TextField
                  fullWidth
                  name="otp"
                  label="Enter OTP sent to Email"
                  value={userData.otp.value}
                  onChange={handleChange1}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]{4}' }}
                  disabled={form1Status !== 2}
                  required
                />
              </Grid>
            )}
            {form1Status >= 3 && (
              <>
                <Grid item sm={6} flexGrow={1}>
                  <TextField
                    fullWidth
                    name="company"
                    label="Company Name"
                    value={userData.company.value}
                    onChange={handleChange1}
                    disabled={form1Status !== 3}
                    required
                  />
                </Grid>
                <Grid item sm={6} flexGrow={1}>
                  <TextField
                    fullWidth
                    name="designation"
                    label="Designation"
                    value={userData.designation.value}
                    onChange={handleChange1}
                    disabled={form1Status !== 3}
                    required
                  />
                </Grid>
                <Grid item sm={6} flexGrow={1}>
                  <TextField
                    fullWidth
                    id="outlined-select-currency"
                    select
                    label="Industry"
                    value={userData.industry.value}
                    name="industry"
                    onChange={handleChange1}
                    disabled={form1Status !== 3}
                    required
                  >
                    <MenuItem key={''} value={''}>
                      -- Select --
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      Industry 1
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      Industry 2
                    </MenuItem>
                    <MenuItem key={3} value={3}>
                      Industry 3
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item sm={6} flexGrow={1}>
                  <TextField
                    fullWidth
                    name="profilePass"
                    label="Profile Password"
                    type={showPassword ? 'text' : 'password'}
                    value={userData.profilePass.value}
                    onChange={handleChange1}
                    disabled={form1Status !== 3}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          {/* </Stack> */}
          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
            {/* <Checkbox name="remember" label="Remember me" /> */}
            <Button type="button" onClick={resetForm1}>
              Reset Form
            </Button>
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" disabled={isLoading} variant="contained">
            {form1Status === 1 ? 'Send OTP' : form1Status === 2 ? 'Verify OTP' : 'Register'}
          </LoadingButton>
        </form>
      )}
      {type === adminRouteName && <></>}
    </>
  );
}
