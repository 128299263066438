import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes, useParams } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import Page404 from './pages/common/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { LoginForm } from './sections/auth/login';
import { RegisterForm } from './sections/auth/register';
import ProtectedLayout from './layouts/protectedLayout/index';
import LandingPage from './pages/common/LandingPage';
import Unauthorized from './pages/common/Unauthorized';
import UnderConstruction from './pages/common/UnderConstruction';

// User Dashboard
import UserDashboardLayout from './layouts/user/UserDashboardLayout';
import UserDashboard from './pages/userPages/dashboard/UserDashboard';
import UserLeads from './pages/userPages/dashboard/UserLeads';
import UserResourceAnalytics from './pages/userPages/dashboard/UserResourceAnalytics';
import UserProductQueries from './pages/userPages/dashboard/UserProductQueries';
import UserMeetings from './pages/userPages/dashboard/UserMeetings';

import { userRouteName, visitorRouteName, showcaseRouteName, adminRouteName } from './utils/constants';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/test',
      children: [
        { element: <Navigate to="/test/1" />, index: true },
        { path: '1', element: <UserPage /> },
        { path: '2', element: <BlogPage /> },
        { path: '3', element: <ProductsPage /> },
        { path: '4', element: <DashboardAppPage /> },
      ],
    },
    {
      path: `/${adminRouteName}`,
      element: <LandingPage type={adminRouteName} />,
      children: [
        { path: '', element: <Navigate to={`/${adminRouteName}/login`} replace /> },
        // { path: 'login', element: <LoginForm type={adminRouteName} />, index: true },
        // { path: 'register', element: <RegisterForm type={userRouteName} /> },
      ],
    },
    {
      path: `/${adminRouteName}/dashboard`,
      element: (
        // <UserDashboardLayout />,
        <ProtectedLayout allowedRoles={['superadmin']}>
          <UserDashboardLayout />
        </ProtectedLayout>
      ),
      children: [
        { path: '', element: <Navigate to={`/${adminRouteName}/dashboard/overview`} replace /> },
        { path: 'overview', element: <UserDashboard />, index: true },
        { path: 'lead', element: <UserLeads /> },
        { path: 'resource-analytics', element: <UserResourceAnalytics /> },
        { path: 'product-queries', element: <UserProductQueries /> },
        { path: 'meeting', element: <UserMeetings /> },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={`/${adminRouteName}/dashboard/`} />, index: true },
        { path: '404', element: <Page404 /> },
        { path: 'under-construction', element: <UnderConstruction /> },
        { path: 'unauthorized', element: <Unauthorized /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },
  ]);

  return routes;
}
