// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Overview',
    path: '/admin/dashboard/overview',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Leads',
    path: '/admin/dashboard/lead',
    icon: icon('ic_user'),
  },
  {
    title: 'Resource Analytics',
    path: '/admin/dashboard/resource-analytics',
    icon: icon('ic_cart'),
  },
  {
    title: 'Product Queries',
    path: '/admin/dashboard/product-queries',
    icon: icon('ic_blog'),
  },
  {
    title: 'Meetings',
    path: '/admin/dashboard/meeting',
    icon: icon('ic_blog'),
  },
];

export default navConfig;
