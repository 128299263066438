import React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
// @mui
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Container, Typography, Grid } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
import { adminRouteName, userRouteName, visitorRouteName } from '../../utils/constants';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  // },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 480,
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: `url('/favicon/samaaroBG.png')`,
  backgroundPosition: 'left',
  backgroundRepeat: 'no-repeat',
  boxShadow: theme.customShadows.card,
  backgroundColor: '#151521',
  backgroundSize: '65vh',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(7, 0),
}));

const StyledGrid = styled('div')(() => ({
  maxHeight: '72vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#131313',
    borderRadius: '8px',
    outline: '1px solid slategrey',
  },
}));

const RegisterCard = styled(Card)`
  min-width: 70vw;

  max-width: 80vw;
  max-height: 80vh;
  @media screen and (max-width: 768px) {
    max-width: 92vw;
  }
  @media screen and (max-width: 821px) {
    min-height: auto;
  }
`;

// ----------------------------------------------------------------------

LandingPage.propTypes = {
  type: PropTypes.string,
};

function LandingPage({ type }) {
  const mdUp = useResponsive('up', 'md');
  const mdDown = useResponsive('down', 'md');
  const typeName = String(type)[0].toUpperCase() + String(type).substring(1).toLowerCase();

  return (
    <>
      <Helmet>
        <title> Showcase {type === userRouteName ? typeName : 'Samaaro'}</title>
      </Helmet>

      <StyledRoot>
        <img
          style={{ width: '100vw', height: '100vh', objectFit: 'cover', position: 'absolute', zIndex: '-1' }}
          src="/assets/backgroundImg/login-register/blurry-gradient-haikei.svg"
          alt="background SVG"
        />
        <RegisterCard>
          <Box>
            <Grid container>
              {mdUp && (
                <Grid item md={4}>
                  <StyledSection>{/* <img src="" alt="Samaaro Background" style={{}} /> */}</StyledSection>
                </Grid>
              )}
              {mdDown && (
                <Grid
                  item
                  md={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexGrow: '1',
                    marginTop: '10px',
                    padding: '5px',
                  }}
                >
                  <img src={'/assets/icons/Samaaro_logo.png'} alt="Samaaro Logo" style={{ objectFit: 'none' }} />
                </Grid>
              )}
              <Grid item md={8} flexGrow={1}>
                <StyledGrid>
                  <Container maxWidth="sm">
                    <StyledContent>
                      <Typography variant="h4" gutterBottom>
                        {window.location.pathname.includes('/login') ? 'Sign in' : 'Register'} to Informa Showcase
                        Analytics
                        {/* {type === adminRouteName ? 'Admin' : ''} */}
                      </Typography>
                      <Outlet />
                    </StyledContent>
                  </Container>
                </StyledGrid>
              </Grid>
            </Grid>
          </Box>
        </RegisterCard>
      </StyledRoot>
    </>
  );
}

export default LandingPage;
